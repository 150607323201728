
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("./components/_Favicons/index.nunjucks");
        
        const __nunjucks_template_import__dep_1 = require("./components/Header/manage-booking.nunjucks");
        
        const __nunjucks_template_import__dep_2 = require("./components/Header/index.nunjucks");
        
        const __nunjucks_template_import__dep_3 = require("./components/Footer/index.nunjucks");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["layout.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "pageTitle"),"Welcome");
frame.set("pageTitle", t_1, true);
if(frame.topLevel) {
context.setVariable("pageTitle", t_1);
}
if(frame.topLevel) {
context.addExport("pageTitle", t_1);
}
output += "\r\n\r\n<!DOCTYPE html>\r\n<!--[if lt IE 7]> <html class=\"no-js lt-ie9 lt-ie8 lt-ie7\"> <![endif]-->\r\n<!--[if IE 7]> <html class=\"no-js lt-ie9 lt-ie8\"> <![endif]-->\r\n<!--[if IE 8]> <html class=\"no-js lt-ie9\"> <![endif]-->\r\n<!--[if gt IE 8]><!-->\r\n<html class=\"no-js\" lang=\"en\">\r\n    <head>\r\n\r\n        <meta charset=\"utf-8\"/>\r\n        <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\"/>\r\n\r\n        <title>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "pageTitle"), env.opts.autoescape);
output += "</title>\r\n\r\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_Favicons/index.nunjucks", false, "layout.nunjucks", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
callback(null,t_2);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
callback(null,t_4);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n    </head>\r\n    <!-- SXA DEFAULT CLASSES ON WRAPPING DIVS -->\r\n    <body class=\"default-device bodyclass\">\r\n        <div id=\"wrapper\">\r\n            <!-- #header -->\r\n            ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("header"))(env, context, frame, runtime, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
output += t_6;
output += "\r\n            <!-- /#header -->\r\n            <!-- #content -->\r\n            <main>\r\n                <div id=\"content\" class=\"row\">\r\n                    ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
output += t_8;
output += "\r\n                </div>\r\n                <div class=\"screensize-detectors\">\r\n                    <div class=\"show-for-small-only\"></div>\r\n                    <div class=\"show-for-medium-only\"></div>\r\n                    <div class=\"show-for-large-only\"></div>\r\n                    <div class=\"show-for-xlarge-only\"></div>\r\n                </div>\r\n            </main>\r\n            <!-- /#content -->\r\n            <!-- #footer -->\r\n            ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/Footer/index.nunjucks", false, "layout.nunjucks", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
callback(null,t_10);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
callback(null,t_12);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n            <!-- /#footer -->\r\n        </div>\r\n\r\n    </body>\r\n</html>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_header(env, context, frame, runtime, cb) {
var lineno = 21;
var colno = 15;
var output = "";
try {
var frame = frame.push(true);
output += "\r\n                ";
if(runtime.contextOrFrameLookup(context, frame, "manageBookingHeader") == true) {
output += "\r\n                    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/Header/manage-booking.nunjucks", false, "layout.nunjucks", false, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
callback(null,t_14);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
callback(null,t_16);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n                ";
});
}
else {
output += "\r\n                    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/Header/index.nunjucks", false, "layout.nunjucks", false, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
callback(null,t_18);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
callback(null,t_20);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n                ";
});
}
output += "\r\n            ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 32;
var colno = 23;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_header: b_header,
b_content: b_content,
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "layout.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "layout.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["layout.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    