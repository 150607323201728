
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../RichText/index.nunjucks");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["components/Footer/index.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/RichText/index.nunjucks", false, "components/Footer/index.nunjucks", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("RichText", t_1);
output += "\r\n\r\n<footer>\r\n    <div id=\"footer\" class=\"row\">\r\n        <div class=\"grid-container\">\r\n            <div class=\"grid-x grid-margin-x\">\r\n                <div class=\"cell auto\">\r\n                ";
output += runtime.suppressValue((lineno = 7, colno = 34, runtime.callWrap(runtime.memberLookup((t_1),"render"), "RichText[\"render\"]", context, ["\r\n                    <p>© National Roads and Motorists' Association Limited. \r\n                        <a href='#'>Link Example</a>. <span class='abn'>ABN 77 000 010 506</span>\r\n                    </p>\r\n                "])), env.opts.autoescape);
output += "\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "components/Footer/index.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "components/Footer/index.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["components/Footer/index.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    