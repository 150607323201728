
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("./nrma-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_1 = require("./racv-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_2 = require("./ract-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_3 = require("./racq-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_4 = require("./racwa-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_5 = require("./aant-company-logo.nunjucks");
        
        const __nunjucks_template_import__dep_6 = require("./raa-company-logo.nunjucks");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["components/_BrandLogos/_index.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "nrma") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/nrma-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racv") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/racv-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "ract") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/ract-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racq") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/racq-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
callback(null,t_13);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racwa") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/racwa-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
callback(null,t_17);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
callback(null,t_19);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "aant") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/aant-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
callback(null,t_21);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
callback(null,t_23);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "raa") {
output += "\r\n    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/raa-company-logo.nunjucks", false, "components/_BrandLogos/_index.nunjucks", false, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
callback(null,t_25);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
callback(null,t_27);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n";
});
}
;
}
;
}
;
}
;
}
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "components/_BrandLogos/_index.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "components/_BrandLogos/_index.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["components/_BrandLogos/_index.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    