
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["components/_Favicons/index.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<!-- FAV ICON -->\r\n<link rel=\"apple-touch-icon\" sizes=\"180x180\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/apple-touch-icon.png\"/>\r\n<link rel=\"icon\" type=\"image/png\" sizes=\"32x32\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/favicon-32x32.png\"/>\r\n<link rel=\"icon\" type=\"image/png\" sizes=\"16x16\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/favicon-16x16.png\"/>\r\n<link rel=\"manifest\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/site.webmanifest\"/>\r\n<link rel=\"mask-icon\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/safari-pinned-tab.svg\" color=\"\"/>\r\n<link rel=\"shortcut icon\" href=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/favicon.ico\"/>\r\n<meta name=\"msapplication-TileColor\" content=\"\"/>\r\n<meta name=\"msapplication-config\" content=\"/fe/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "/assets/favicons/browserconfig.xml\"/>\r\n<meta name=\"theme-color\" content=\"#ffffff\"/>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "components/_Favicons/index.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "components/_Favicons/index.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["components/_Favicons/index.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    