
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["components/_BrandLogos/aant-company-logo.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<svg width=\"183\" height=\"44\" viewBox=\"0 0 183 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n    <path d=\"M101.11 44L100.061 41.527L82.4326 0H73.5927L63.7061 23.2854C67.0643 23.0643 70.1741 23.0923 73.0705 23.3877L77.8862 11.5461L83.8158 26.1259L91.0869 44H101.11Z\" fill=\"#2E486A\"/>\r\n    <path d=\"M132.165 27.0472L111.67 0H102.902V44H112.366V16.3921L133.307 44H141.627V0H132.165V27.0472Z\" fill=\"#2E486A\"/>\r\n    <path d=\"M146.793 0V8.86142H160.054V44H169.641V8.86142H182.905V0H146.793Z\" fill=\"#2E486A\"/>\r\n    <path d=\"M101.11 44C101.11 44 100.488 41.7713 98.3742 38.7562C87.9352 23.8716 74.3562 20.3703 50.5796 25.1256C38.2492 27.5893 28.3927 26.2632 21.2771 21.1799C15.1595 16.8109 11.1399 9.68963 9.28786 0H0C2.12121 12.949 7.48459 22.5851 15.9694 28.6455C25.2225 35.2526 37.4671 37.0928 52.3666 34.1126C75.0826 29.5691 83.4515 33.6148 91.0869 43.9953L91.0892 43.993L91.0869 44H101.11Z\" fill=\"url(#paint0_linear_1096_1288)\"/>\r\n    <path d=\"M57.8484 36.2436L42.4614 0H33.6215L23.9113 22.8759C26.3899 24.202 29.14 25.1465 32.1594 25.7072L37.9196 11.5461L51.118 44H64.5949L68.1017 35.3596C64.7435 35.3596 61.6498 35.6504 57.8484 36.2436Z\" fill=\"#2E486A\"/>\r\n    <path d=\"M14.9413 44.0001H24.7166L27.476 37.2278C24.4798 36.5322 21.1843 35.2108 19.1142 34.1755L14.9413 44.0001Z\" fill=\"#2E486A\"/>\r\n    <defs>\r\n        <linearGradient id=\"paint0_linear_1096_1288\" x1=\"12.4305\" y1=\"35.3394\" x2=\"89.9843\" y2=\"8.33677\" gradientUnits=\"userSpaceOnUse\">\r\n            <stop stop-color=\"#ED1C24\"/>\r\n            <stop offset=\"1\" stop-color=\"#B8222F\"/>\r\n        </linearGradient>\r\n    </defs>\r\n</svg>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "components/_BrandLogos/aant-company-logo.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "components/_BrandLogos/aant-company-logo.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["components/_BrandLogos/aant-company-logo.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    