
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../_BrandLogos/_index.nunjucks");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["components/Header/index.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\r\n";
output += "\r\n\r\n<header>\r\n   <div id=\"header\" class=\"row\">\r\n      <div class=\"grid-container\">\r\n         <div class=\"grid-x grid-margin-x\">\r\n            <div class=\"cell small-12\">\r\n               <div class=\"hdr-wrapper\">\r\n                  <div class=\"logo-container\">\r\n                     <div class=\"brand-logo\">\r\n                        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("components/_BrandLogos/_index.nunjucks", false, "components/Header/index.nunjucks", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\r\n                     </div>\r\n                     <div class=\"page-title-container\">\r\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "nrma") {
output += "\r\n                           <h1>Car batteries</h1>\r\n                           <div class=\"sub-heading\">Replacements delivered and safely installed</div>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racv") {
output += "\r\n                           <h1>Car batteries</h1>\r\n                           <div class=\"sub-heading\">Replacements delivered and safely installed</div>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "ract") {
output += "\r\n                           <h1>Car batteries</h1>\r\n                           <div class=\"sub-heading\">Replacements delivered and safely installed</div>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racq") {
output += "\r\n                           <h1>Battery Finder</h1>\r\n                           <div class=\"sub-heading\">Find the right battery for your vehicle in seconds</div>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "racwa") {
output += "\r\n                           <h1>Car batteries</h1>\r\n                           <div class=\"sub-heading\">Replacements delivered and safely installed</div>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "aant") {
output += "\r\n                           <h1>Battery Finder</h1>\r\n                        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") == "raa") {
output += "\r\n                           <h1>Car batteries</h1>\r\n                           <div class=\"sub-heading\">Keep moving with a mobile battery replacement</div>\r\n                        ";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
output += "\r\n                     </div>\r\n                  </div>\r\n               </div>\r\n            </div>\r\n         </div>\r\n      </div>\r\n   </div>\r\n</header>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "components/Header/index.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "components/Header/index.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["components/Header/index.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    