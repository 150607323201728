"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VEHICLE_PRIORITY_ERRORS = exports.REGEX_POSTCODE = exports.REGEX_DDMMYYYY = exports.REGEX_YYYYMMDD = exports.REGEX_PHONENUMBERAU = exports.REGEX_EMAILADDRESS = exports.REGEX_MEMBER_NUMBER = exports.REGEX_REGO_NUMBER = exports.REGEX_NUMERIC = exports.REGEX_ALPHAANDSPACES = exports.REGEX_ADDRESS = exports.REGEX_ALPHANUMERICANDSPACES = exports._MAX_CHAR_MEMBER_NUMBER = exports._MIN_CHAR_MEMBER_NUMBER = exports._min_char_model = exports._min_char_make = exports._min_char_location = exports.GENERAL_ERROR_MESSAGE = exports.BREAKPOINTS = exports.LOADING_CALL = exports.LOADING_STATUS = exports._loadBlockerDelay = exports._loadContainerDelay = exports.WINDOW_MESSAGES = void 0;
//Client Managed Text
exports.WINDOW_MESSAGES = window.messages || {};
var $vehicleManagedText = exports.WINDOW_MESSAGES === null || exports.WINDOW_MESSAGES === void 0 ? void 0 : exports.WINDOW_MESSAGES.Vehicle;
exports._loadContainerDelay = 650;
exports._loadBlockerDelay = 300;
exports.LOADING_STATUS = {
    init: "init",
    loading: "loading",
    loadingAutoComplete: "loadingAutoComplete",
    loaded: "loaded",
    error: "error",
    noResults: "noResults",
    success: "success",
};
exports.LOADING_CALL = {
    locationFind: "locationFind",
    locationRetrieve: "locationRetrieve",
    locationReverseGeo: "locationReverseGeo",
    locationContinue: "locationContinue",
    vehicleMakesFetchStart: "vehicleMakesFetchStart",
    vehicleRegoSearch: "vehicleRegoSearch",
    vehicleMakesSearch: "vehicleMakesSearch",
    vehicleModelsFetchStart: "vehicleModelsFetchStart",
    vehicleModelsSearch: "vehicleModelsSearch",
    vehicleYearGrouping: "vehicleYearGrouping",
    vehicleVariant: "vehicleVariant",
    vehicleContinue: "vehicleContinue",
    batteryFetchStart: "batteryFetchStart",
    batteryByStockNumber: "batteryByStockNumber",
    batteryMembership: "batteryMembership",
    batteryContinue: "batteryContinue",
    appointmentFetchDateTime: "appointmentFetchDateTime",
    appointmentConfirm: "appointmentConfirm",
    getBooking: "getBooking",
    updateBooking: "updateBooking",
    cancelBooking: "cancelBooking",
};
exports.BREAKPOINTS = {
    small: "small",
    medium: "medium",
    large: "large",
    xlarge: "xlarge",
};
exports.GENERAL_ERROR_MESSAGE = "Error: Cannot process request";
exports._min_char_location = 2;
exports._min_char_make = 1;
exports._min_char_model = 1;
//TODO: In future add all brand related settings to ./brand-settings.jsx - requires a bit of testing..
exports._MIN_CHAR_MEMBER_NUMBER = {
    nrma: 0,
    racv: 1,
    ract: 4,
    racq: 16,
    racwa: 9,
};
exports._MAX_CHAR_MEMBER_NUMBER = {
    nrma: 9,
    racv: 10,
    ract: 7,
    racq: 16,
    racwa: 9,
};
//REGEX
exports.REGEX_ALPHANUMERICANDSPACES = /^[\w\-\s]+$/;
exports.REGEX_ADDRESS = /^[a-zA-Z0-9\s,'-]*$/;
exports.REGEX_ALPHAANDSPACES = /^([a-zA-Z\s])+$/;
exports.REGEX_NUMERIC = /^\d+$/;
exports.REGEX_REGO_NUMBER = /^([A-Za-z0-9]{1,9})$/;
exports.REGEX_MEMBER_NUMBER = (process.env.ENV_BRAND === "racwa" ? /^[0-3]{1}[0-9]*$/ : /^\d+$/);
exports.REGEX_EMAILADDRESS = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
exports.REGEX_PHONENUMBERAU = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){10}$/;
exports.REGEX_YYYYMMDD = /(?:(?:(?:(?:1[8-9]|20)(?:04|08|[2468][048]|[13579][26]))|2000)([-/])02(?:\1)29|(?:(?:1[8-9]|20)\d\d)([-/])(?:(?:0[1-9]|1[0-2])(?:\2)(?:0[1-9]|1[0-9]|2[0-8])|(?:0(?:1|[3-9])|(?:1[0-2]))(?:\2)(?:29|30)|(?:0[13578]|1[02])(?:\2)31))/;
exports.REGEX_DDMMYYYY = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
exports.REGEX_POSTCODE = /^[0-9]{4}$/;
exports.VEHICLE_PRIORITY_ERRORS = [
    {
        priority: 1,
        type: "OEM",
        status: "YES",
        message: $vehicleManagedText && ($vehicleManagedText === null || $vehicleManagedText === void 0 ? void 0 : $vehicleManagedText.VehicleOEMFitmentError)
    },
    {
        priority: 2,
        type: "BATTERY",
        status: "NO",
        message: $vehicleManagedText && ($vehicleManagedText === null || $vehicleManagedText === void 0 ? void 0 : $vehicleManagedText.BatteryAvailabilityError)
    },
    {
        priority: 3,
        type: "WORKSHOP",
        status: "YES",
        message: $vehicleManagedText && ($vehicleManagedText === null || $vehicleManagedText === void 0 ? void 0 : $vehicleManagedText.WorkshopFitmentError)
    },
];
